import { defineStore } from 'pinia'
import axiosInstance, { post_login, logout, get_user } from '../axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as {
      email: string
      id: string
      is_active: boolean
      is_superuser: boolean
      is_verified: boolean
      is_ro: boolean
      ireboxes?: string[]
    } | null,
    expirationTime: null as number | null,
  }),
  actions: {
    async initializeAuth() {
      const res_user = await get_user()
      if (res_user.status === 401) {
        await this.logout()
        return
      }
      if (res_user && 'data' in res_user && res_user.status !== 401) {
        this.user = res_user.data
        const _expirationTime = parseInt(localStorage.getItem('expirationTime') || '{}')

        if (this.isAuthenticated() && _expirationTime) {
          const currentTime = new Date().getTime()
          if (currentTime < _expirationTime) {
            this.expirationTime = _expirationTime
          } else {
            await this.logout()
          }
        } else {
          await this.logout()
        }
      } else {
        await this.logout()
      }
    },
    async login(credentials: { email: string; password: string; keepLoggedIn: boolean }) {
      const response = await post_login(credentials)

      // Overenie, či je 'response' typu AxiosResponse a obsahuje 'data'
      if (response && 'data' in response && response.status === 200) {
        const expirationTime = new Date().getTime() + 60 * 60 * 1000 // 1 hour expiration

        localStorage.setItem('token', response.data.access_token)
        localStorage.setItem('expirationTime', String(expirationTime))

        const res_user = await get_user()
        if (res_user && 'data' in res_user && res_user.status !== 401) {
          this.user = res_user.data
          this.expirationTime = expirationTime
        } else {
          await this.logout()
        }
      }
    },
    async logout() {
      this.expirationTime = null
      await logout()
      delete axiosInstance.defaults.headers.common['Authorization']
      localStorage.removeItem('token')
      localStorage.removeItem('expirationTime')
      this.user = null
      this.expirationTime = null
    },
    getToken() {
      return localStorage.getItem('token')
    },
    isAuthenticated() {
      return this.user !== null
    },
    isSuperUser() {
      return this.user?.is_superuser
    },
    isReadOnly() {
      return this.user?.is_ro
    },
    get_user() {
      return this.user
    },
  },
})
